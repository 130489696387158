<template>
  <form
    ref="form"
    @submit.prevent="next"
  >
    <c-title>Antragsformular</c-title>

    <p class="mt-8 mb-8">
      Alle Felder außer den explizit ausgenommenen sind Pflichtfelder.
    </p>
    <c-form-fieldset
      legend="Projektträger:in"
      class="mb-8"
    >
      <c-form-text
        v-model="fields.contact_person"
        :errors="errors.contact_person"
        class="mb-8"
        label="Ansprechpartner:in"
        autocomplete="name"
        required
      />
      <c-form-text
        v-model="fields.organization"
        :errors="errors.organization"
        class="mb-8"
        label="Projektträger:in"
        help="(Entweder eine Organisation z.B. Verein, gGmbH oder eine Person, ggf. identisch mit Ansprechpartner:in)"
        required
      />
      <c-form-text
        v-model="fields.address"
        :errors="errors.address"
        class="mb-8"
        label="Straße und Hausnummer"
        autocomplete="street-address"
        maxlength="100"
        required
      />
      <c-form-columns :columns="[1, 5]">
        <c-form-text
          v-model="fields.postal_code"
          :errors="errors.postal_code"
          class="mb-8"
          label="Postleitzahl"
          autocomplete="postal-code"
          inputmode="numeric"
          pattern="[0-9]*"
          minlength="5"
          maxlength="5"
          required
        />
        <c-form-text
          v-model="fields.city"
          :errors="errors.city"
          class="mb-8"
          label="Ort"
          autocomplete="address-level2"
          maxlength="100"
          required
        />
      </c-form-columns>
      <c-form-text
        v-model="fields.contact_number"
        :errors="errors.contact_number"
        class="mb-8"
        label="Telefonnummer"
        autocomplete="tel"
        required
      />
      <c-form-text
        v-model="fields.email"
        :errors="errors.email"
        class="mb-8"
        label="E-Mail-Adresse"
        type="email"
        autocomplete="email"
        required
      />
    </c-form-fieldset>
    <c-form-fieldset
      legend="Angaben zum Projekt"
      class="mb-8"
    >
      <c-form-text
        v-model="fields.project_title"
        :errors="errors.project_title"
        class="mb-8"
        label="Projekttitel"
        required
      />
      <c-form-text
        v-model="fields.event_dates"
        :errors="errors.event_dates"
        class="mb-8"
        label="Veranstaltungstermin(e)"
        required
        tag="textarea"
        maxlength="300"
        rows="3"
      />
      <c-form-text
        v-model="fields.event_location"
        :errors="errors.event_location"
        class="mb-8"
        label="Veranstaltungsort"
        required
      />
      <c-form-text
        v-model="fields.message_text"
        :errors="errors.message_text"
        class="mb-8"
        label="Kurzbeschreibung des Projekts und Nennung der Projektbeteiligten"
        :help="`max. 1500 Zeichen, inkl. Leerzeichen`"
        required
        tag="textarea"
        maxlength="1500"
        rows="20"
      />
    </c-form-fieldset>
    <c-form-fieldset
      legend="Kosten/Finanzierung"
      class="mb-8"
    >
      <c-form-text
        v-model="fields.total_cost"
        :errors="errors.total_cost"
        class="mb-8"
        label="Gesamtkosten"
        required
      />
      <c-form-text
        v-model="fields.secured_funds"
        :errors="errors.secured_funds"
        class="mb-8"
        label="Gesicherte Mittel (Summe und Förderer)"
        required
      />
      <c-form-text
        v-model="fields.application_amount"
        :errors="errors.application_amount"
        class="mb-8"
        label="Antragssumme Claussen-Simon-Stiftung"
        required
      />
      <c-form-text
        :errors="errors.detailed_project_description"
        class="mt-5 mb-8"
        type="file"
        :file="fields.detailed_project_description"
        label="Ausführliche Projektbeschreibung (3 bis max. 5 Seiten)"
        :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX. Maximale Dateigröße: 5 MB.`"
        accept=".pdf,.doc,.docx"
        required
        @change="fields.detailed_project_description = $event"
      />
      <c-form-text
        :errors="errors.cost_plan_file"
        class="mt-5 mb-8"
        type="file"
        :file="fields.cost_plan_file"
        label="Kostenplan"
        :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
        accept=".pdf,.doc,.docx,.xls,.xlsx"
        required
        @change="fields.cost_plan_file = $event"
      />
    </c-form-fieldset>
    <c-button>
      Nächster Schritt
    </c-button>
  </form>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

export default {
  name: 'VRequestStep0',

  extends: BRequestStep,

  data () {
    return {
      fields: {
        contact_person: '',
        organization: '',
        address: '',
        postal_code: '',
        city: '',
        contact_number: '',
        email: '',
        project_title: '',
        event_dates: '',
        event_location: '',
        message_text: '',
        application_amount: '',
        secured_funds: '',
        detailed_project_description: '',
        cost_plan_file: ''
      }
    }
  },

  computed: {
    step: () => 0,
    id: () => 'reportData'
  }
}
</script>
